import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from "react-router-dom";
import { IUser, IEvent } from "@eagerdog/interfaces";

import { helperService } from "../../services/helper.service";

import { Modal, useModal } from "../../components/Modal/Modal";
import DogForm from "../../components/StepForm/DogForm/DogForm";
import EventForm from "../../components/StepForm/EventForm/EventForm";

import './UserMenu.scss';

interface IProps {}

interface IUserMenuItem {
  onClick?(): void,
  url?: string,
  icon: string,
  label: string,
  target?: string
}

const UserMenu: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isShown, toggle } = useModal();

  const user:IUser = helperService.getUser();
  const pUserType = helperService.usePrevious(user.user_type);

  const [userDrop, setUserDrop] = useState<boolean>(false);
  const [eventData, setEventData] = useState<any>();

  const [loaded, setLoaded] = useState<boolean>(false);

  const [menuItems, setMenuItems] = useState<IUserMenuItem[]>();

  // Use to prevent the navigation to home page upon logout
  const noAutoNavigatePaths = ["/e/"];

  const shouldPreventNavigation = () => {
    return noAutoNavigatePaths.some(path => location.pathname.includes(path));
  };

  useEffect(() => {
    if (!loaded) {
      const queryParameters = new URLSearchParams(window.location.search);
      const stripe = queryParameters.get("stripe");

      if (stripe === "1") {
        let _eventData:any = {};

        for (const [key, value] of queryParameters.entries()) {
          _eventData[key] = value;
        }

        setEventData(_eventData);

        toggle();
      }

      setLoaded(true);
    }
  }, [toggle, loaded]);

  useEffect(() => {
    if (user.user_type.length > 0 && (pUserType !== user.user_type) && helperService.isLoggedIn()) {
      switch (user.user_type) {
        case "owner":
          setMenuItems([
            {
              onClick: () => {
                toggle();
              },
              icon: "paw",
              label: "Add a Dog"
            }, {
              url: "/account",
              icon: "cog",
              label: "Account Settings"
            }, {
              url: "https://eagerdog.zendesk.com/hc/en-us",
              icon: "help",
              label: "Help",
              target: "_blank"
            }
          ]);
        break;
        case "club_manager":
          setMenuItems([
            {
              onClick: () => {
                toggle();
              },
              icon: "calendar",
              label: "Create an Event"
            }, {
              url: "/account",
              icon: "cog",
              label: "Account Settings"
            }, {
              url: "https://eagerdog.zendesk.com/hc/en-us",
              icon: "help",
              label: "Help",
              target: "_blank"
            }
          ]);
        break;
      }
    }
  }, [user, pUserType, toggle]);

  const isOwner = user.user_type === "owner";
  return(
    <div className="UserMenu">
      {helperService.isLoggedIn() && 
        <div className="userWrap">
          <div className="userProfile">
            <div onClick={() => { setUserDrop(!userDrop); }} className="userIcon"></div>
            {userDrop && <div className="userDrop">
              <div className="userInfo">
                <div className="displayName">{user.display_name}</div>
                <div className="email">{user.email}</div>
              </div>
              <ul className="userList">
                {menuItems && menuItems.map((item:IUserMenuItem, index:number) => {
                  return(
                    <li key={index} onClick={() => { if (item.onClick) { item.onClick(); } setUserDrop(false); }}>
                      {item.url &&
                        <Link className="liWrap" to={item.url} target={item.target ? item.target : undefined}>
                          <div className={"liIcon " + item.icon}></div>
                          <span>{item.label}</span>
                        </Link>
                      }
                      {!item.url && <div className="liWrap">
                        <div className={"liIcon " + item.icon}></div>
                        <span>{item.label}</span>
                      </div>}
                    </li>
                  );
                })}
              </ul>
              <div className="actions">
                <button onClick={() => { setUserDrop(false); helperService.logout(() => {
                   if (!shouldPreventNavigation()) {
                    navigate("/");
                   } else {
                    // reloading to refresh roles / actions
                    window.location.reload();
                   }
                }); }}>Logout</button>
              </div>
            </div>}
          </div>
        </div>
      }
      {!helperService.isLoggedIn() && <><a className="about" href="https://about.eagerdog.com">Learn More</a><Link to="/login">Login</Link></>}
      <Modal
        className={isOwner ? "dogForm" : "eventForm"}
        isShown={isShown}
        hide={toggle}
        modalContent={isOwner ? <DogForm onFinish={() => { toggle(); }} /> : <EventForm prePopulate={eventData} onFinish={(event: IEvent) => { toggle(); navigate("/events?e=" + event.handle, { replace: true }); setEventData(undefined); }} />}
      />
    </div>
  );
}

export default UserMenu;