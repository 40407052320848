import React, { useState, useEffect } from "react";
import moment from "moment";
import { AxiosError } from "axios";

import { IShow, IEvent, ShowRunTimeSlotDto } from "@eagerdog/interfaces";
import { Constants } from "@eagerdog/Constants";

import { apiService } from "../../../services/api.service";
import { helperService, nonLicensed } from "../../../services/helper.service";

import StepForm, { Step, useStepForm } from "../StepForm";
import Input from "../../Input/Input";
import DatePick from "../../DatePick/DatePick";
import Checkbox, { ICheck }from "../../Checkbox/Checkbox";
import Dropdown, { IOption } from "../../Dropdown/Dropdown";
import Button from "../../Button/Button";
import Chip from "../../Chip/Chip";
import Timeslots from "src/components/Timeslots/Timeslots";
import { toast } from "../../Toast/ToastManager";

import "./ShowForm.scss";

interface IProps {
  onFinish(): void,
  clubId: string | undefined,
  event: IEvent | undefined,
  show?: IShow,
  currency?: string
}

interface ICheckJudge extends ICheck {
  judge_name: string,
  judge_number?: string,
  price?: string,
  earlyBirdPrice?: string
}

export interface ShowRunTimeSlotDtoId extends ShowRunTimeSlotDto {
  id?: string
}

const ShowForm: React.FC<IProps> = (props) => {
  const { switchToTab, activeTab } = useStepForm();
  const [showType, setShowType] = useState<IOption>(props.event && props.event.sanctioning_club === Constants.sanctioning_club.UKC ? { value: Constants.show_type.nosework, id: Constants.show_type.nosework } : { value: Constants.show_type.conformation, id: Constants.show_type.conformation });

  let startDate = new Date(Date.now());
  startDate.setMinutes(0);
  startDate.setHours(9);

  const [isCreatingShow, setIsCreatingShow] = useState<boolean>(false);

  const [showName, setShowName] = useState<IOption>({ value: "Select a Show Name", id: "Select a Show Name" });
  const [showDate, setShowDate] = useState<Date>(startDate);

  const [price, setPrice] = useState<string>("");
  const [useEarlyBird, setUseEarlyBird] = useState<boolean>(false);

  const [useSameJudge, setUseSameJudge] = useState<boolean>(true);
  const [useSamePrice, setUseSamePrice] = useState<boolean>(true);

  const earlyDate = new Date();
  earlyDate.setMinutes(0);

  const [earlyBirdEndDate, setEarlyBirdEndDate] = useState<Date>(earlyDate);
  const [earlyBirdPrice, setEarlyBirdPrice] = useState<string>("");

  const [maxDog, setMaxDog] = useState<string>("");

  //FAST CAT
  /*const [firstRunTime, setFirstRunTime] = useState<Date>(startDate);
  const [runDuration, setRunDuration] = useState<string>("");*/

  const [isTrailingShow, setIsTrailingShow] = useState<boolean>(false);
  const [previousShow, setPreviousShow] = useState<IOption>({ value: "Select a Previous Show", id: "Select a Previous Show" });

  const [useCloseDate, setUseCloseDate] = useState<boolean>(false);
  const pUseCloseDate = helperService.usePrevious(useCloseDate);

  const [eventShows, setEventShows] = useState<IShow[]>([]);
  const [eventShowOptions, setEventShowOptions] = useState<IOption[]>([{ value: "Select a Previous Show", id: "Select a Previous Show" }]);

  const [akcNum, setAkcNum] = useState<string>("");

  const [notifEmail, setNotifEmail] = useState<string>("");
  const [notifEmails, setNotifEmails] = useState<string[]>([]);
  const [notifRender, setNotifRender] = useState<boolean>(false);

  const cDate = new Date();
  cDate.setMinutes(0);

  const [closeDate, setCloseDate] = useState<Date>(cDate);

  const [showTypes, setShowTypes] = useState<IOption[]>([
    { value: Constants.show_type.nosework, id: Constants.show_type.nosework },
    { value: Constants.show_type.conformation, id: Constants.show_type.conformation },
    { value: Constants.show_type.obedience, id: Constants.show_type.obedience },
    { value: Constants.show_type.rally_obedience, id: Constants.show_type.rally_obedience },
    { value: Constants.show_type.agility, id: Constants.show_type.agility },
    { value: Constants.show_type.weight_pull, id: Constants.show_type.weight_pull }
  ]);

  const [showElements, setShowElements] = useState<ICheckJudge[]>([
    { label: Constants.dog_class_element.nosework_container, checked: true, judge_name: "", judge_number: "" },
    { label: Constants.dog_class_element.nosework_exterior, checked: true, judge_name: "", judge_number: "" },
    { label: Constants.dog_class_element.nosework_interior, checked: true, judge_name: "", judge_number: "" },
    { label: Constants.dog_class_element.nosework_vehicle, checked: true, judge_name: "", judge_number: "" },
    { label: Constants.dog_class_element.nosework_handler_descrimination, checked: true, judge_name: "", judge_number: "" }
  ]);

  const [timeslots, setTimeslots] = useState<ShowRunTimeSlotDtoId[]>([{
    time_slot: "",
    num_available: 0,
    note: ""
  }]);

  const validateShow = () => {
    let passed:string = "";

    if (showName.value === "Select a Show Name") {
      passed = "Please select a name for your show";
      switchToTab(1);
    }

    let hasElement:boolean = false;

    for (let i in showElements) {
      if (showElements[i].checked) {
        hasElement = true;
        break;
      }
    }

    if (!hasElement) {
      passed = "Please select at least one element for your show";
      switchToTab(0);
    }

    if (useEarlyBird && moment(earlyBirdEndDate).format("X") > moment(showDate).format("X") && !isTrailingShow) {
      passed = "Your early bird end date cannot be after the show start date";
      if (showType.id === Constants.show_type.fast_cat) {
        switchToTab(4);
      } else {
        switchToTab(3);
      }
      
    }

    if (isTrailingShow && previousShow.value === "Select a Previous Show") {
      passed = "Please select a Previous Show";
      switchToTab(1);
    }

    /*if (showType.id === Constants.show_type.fast_cat) {
      if (maxDog === "" || runDuration === "") {
        passed = "Please provide a run duration and dog limit";
        switchToTab(2);
      }
    }*/

    return passed;
  }

  const createShow = () => {
    let _error: string = validateShow();

    if (_error.length > 0) {
      toast.show({
        title: "Show Error",
        content: _error,
        duration: 10000,
        type: "fail",
      });

      return false;
    }

    setIsCreatingShow(true);

    let payload: any = {
      show_name: showName.value,
      show_type: showType.value,
      show_date: showDate,
      fee: parseInt(price),
      show_elements: showElements.filter(e => e.checked).map(e => {
        let _se:any = { show_element: e.label, judge_name: e.judge_name, judge_number: e.judge_number };

        if (
          (showType.value === Constants.show_type.rally_obedience || showType.value === Constants.show_type.obedience) || 
          (props.event?.sanctioning_club === Constants.sanctioning_club.AKC && showType.value === Constants.show_type.conformation)
        ) {
          _se.show_element = showType.value;
          _se.level = e.label;
        }

        if (showType.value === Constants.show_type.conformation && nonLicensed.includes(e.label) && e.label !== Constants.dog_class_element.junior_showmanship) {
          _se.show_element = Constants.dog_class_element.conformation_non_licensed;
          _se.level = e.label;
        }

        if (e.price) {
          _se.fee = e.price;
        }

        if (useEarlyBird && e.earlyBirdPrice) {
          _se.early_bird_fee = e.earlyBirdPrice
        }

        return _se;
      }),
      use_registration_end_date: useCloseDate,
      use_early_bird_fee: useEarlyBird,
      use_same_fee: useSamePrice,
      notification_emails: notifEmails
    };

    if (props.event?.sanctioning_club === Constants.sanctioning_club.AKC) {
      payload.event_number = akcNum;
    }

    if (useCloseDate) {
      payload.registration_end_date = closeDate;
    }

    if (useEarlyBird) {
      payload.early_bird_end_date = earlyBirdEndDate;
      payload.early_bird_fee = parseInt(earlyBirdPrice);
    }

    if (maxDog.length > 0) {
      payload.max_dogs = parseInt(maxDog);
    }

    if (isTrailingShow) {
      payload.trailing_show = true;

      let _previousShow = getShowById(previousShow.id);

      if (_previousShow) {
        payload.previous_show_name = _previousShow.show_name;
        payload.show_date = moment(_previousShow.show_date).add(1, 'minutes');
      }
    }

    if (showType.id === Constants.show_type.fast_cat) {
      /*payload.max_dogs = parseInt(maxDog);
      payload.first_run_time_minutes = parseInt(moment(firstRunTime).format("H")) * 60;
      payload.run_duration_minutes = parseInt(runDuration);*/
      payload.time_slots = timeslots;
    }

    if (props.event) {
      if (props.show) {
        apiService.updateShow(props.event._id, props.show._id, payload).then((response) => {
          if (response._id) {
            props.onFinish();
          }
        }).catch((e: AxiosError) => {
          toast.show({
            title: "Edit show",
            content: "Unable to edit show",
            duration: 10000,
            errorDetails: e,
            type: "fail",
          });
        }).finally(() => {
          setIsCreatingShow(false);
        });
      } else {
        apiService.createShow(props.event._id, payload).then((response) => {
          if (response._id) {
            props.onFinish();
          }
        }).catch((e: AxiosError) => {
          toast.show({
            title: "Create show",
            content: "Unable to create show",
            duration: 10000,
            errorDetails: e,
            type: "fail",
          });
        }).finally(() => {
          setIsCreatingShow(false);
        });
      }
    }
  };

  const getSameJudge = () => {
    let _showElements:ICheckJudge[] = [...showElements];

    for (let i in _showElements) {
      if (_showElements[i].judge_name !== "" && _showElements[i].judge_number !== "") {
        return _showElements[i];
      }
    }
  }

  const toggleCheck = (label:string) => {
    let _showElements:ICheckJudge[] = [...showElements];

    for (let i in _showElements) {
      if (_showElements[i].label === label) {
        _showElements[i].checked = !_showElements[i].checked;

        if (_showElements[i].checked && useSameJudge) {
          let _judge:ICheckJudge | undefined = getSameJudge();

          if (_judge) {
            _showElements[i].judge_name = _judge.judge_name;
            _showElements[i].judge_number = _judge.judge_number;            
          }

        }

        break;
      }
    }

    setShowElements(_showElements);
  }

  const updateSingleJudge = (judge: any) => {
    let _showElements:ICheckJudge[] = [...showElements];
    
    for (let i in _showElements) {
      _showElements[i] = { ...showElements[i], ...judge };
    }

    setShowElements(_showElements);
  }

  const updateJudge = (index: number, judge: any) => {
    let _showElements:ICheckJudge[] = [...showElements];
    _showElements[index] = { ...showElements[index], ...judge };

    setShowElements(_showElements);
  }

  const updateNonLicensedPrice = (label: string, value: string, earlyBird?: boolean) => {
    let _showElements:ICheckJudge[] = [...showElements];

    for (let _se in _showElements) {
      if (label === _showElements[_se].label) {
        if (earlyBird) {
          _showElements[_se].earlyBirdPrice = value;
        } else {
          _showElements[_se].price = value;
        }

        break;
      }
    }

    setShowElements(_showElements);
  }

  const setNonLicensedPrices = (amt: string) => {
    let _showElements:ICheckJudge[] = [...showElements];

    for (let s in _showElements) {
      if (nonLicensed.includes(_showElements[s].label)) {
        if (_showElements[s].price === undefined) {
          _showElements[s].price = amt;
        }

        if (_showElements[s].earlyBirdPrice === undefined) {
          _showElements[s].earlyBirdPrice = amt;
        }
      }
    }

    if (useEarlyBird && earlyBirdPrice.length === 0) {
      setEarlyBirdPrice(price);
    }

    setShowElements(_showElements);
  }

  const showTypeHasElements = (showType: string) => {
    if (showType !== Constants.show_type.agility && 
        showType !== Constants.show_type.weight_pull && 
        showType !== Constants.show_type.fast_cat &&
        showType !== Constants.show_type.fetch) {
      return false;
    }

    return true;
  }

  const getShowNamesByType = (showType: string) => {
    switch (showType) {
      case Constants.show_type.conformation:
        return [
          { id: Constants.show_name.show_1, value: Constants.show_name.show_1 },
          { id: Constants.show_name.show_2, value: Constants.show_name.show_2 }
        ];
    }

    return [
      { id: Constants.show_name.trial_1, value: Constants.show_name.trial_1 },
      { id: Constants.show_name.trial_2, value: Constants.show_name.trial_2 }
    ];
  }

  const isSecondShow = () => {
    return (showName.value === "Trial 2" || showName.value === "Show 2");
  }

  const getShowById = (id: string) => {
    for (let i in eventShows) {
      if (eventShows[i]._id === id) {
        return eventShows[i];
      }
    }

    return undefined;
  }

  const submitNotifEmail = (email: string) => {
    if (helperService.isValidEmail(email)) {
      setNotifEmails([...notifEmails, email]);
      setNotifEmail("");
      setNotifRender(true);
    } else {
      toast.show({
        title: "Show Notifications",
        content: "Not a valid email",
        duration: 10000,
        type: "fail",
      });
    }
  }

  const removeNotifEmail = (index: number) => {
    let _notifEmails:string[] = [...notifEmails];

    _notifEmails.splice(index, 1);

    setNotifEmails(_notifEmails);
  }

  useEffect(() => {
    if (notifRender) {
      setNotifRender(false);
    }
  }, [notifRender, setNotifRender]);

  const pShowType = helperService.usePrevious(showType);

  useEffect(() => {
    if (pShowType !== showType) {
      let _showElements:ICheckJudge[] = [];

      if (props.event && props.event.sanctioning_club) {
        if (props.event.sanctioning_club === Constants.sanctioning_club.UKC) {
          switch(showType.id) {
            case Constants.show_type.nosework:
              _showElements = [
                { label: Constants.dog_class_element.nosework_container, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element.nosework_exterior, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element.nosework_interior, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element.nosework_vehicle, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element.nosework_handler_descrimination, checked: false, judge_name: "", judge_number: "" }
              ];
            break;
            case Constants.show_type.conformation:
              _showElements = [
                { label: Constants.dog_class_element.conformation, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element.conformation_altered, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element.novice_puppy, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element.veteran, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element.brood_bitch_stud_dog, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element.brace, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element.total_dog, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element.junior_showmanship, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element_level.total_junior, checked: false, judge_name: "", judge_number: "" }
              ];
            break;
            case Constants.show_type.obedience:
            _showElements = [
                { label: Constants.dog_class_element_level.pre_novice, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element_level.beginner_novice, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element_level.novice_a, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element_level.novice_b, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element_level.novice_c, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element_level.advance_novice, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element_level.open_a, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element_level.open_b, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element_level.advance_open, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element_level.utility_a, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element_level.utility_b, checked: false, judge_name: "", judge_number: "" }
              ];
            break;
            case Constants.show_type.rally_obedience:
              _showElements = [
                { label: Constants.dog_class_element_level.ro1_a, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element_level.ro1_b, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element_level.ro1_c, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element_level.ro2_a, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element_level.ro2_b, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element_level.ro3_a, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element_level.ro3_b, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element_level.rom_a, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element_level.rom_b, checked: false, judge_name: "", judge_number: "" }
              ];
            break;
            case Constants.show_type.agility:
              _showElements = [
                { label: Constants.dog_class_element.performance_agility, checked: false, judge_name: "", judge_number: "" }
              ];
            break;
            case Constants.show_type.weight_pull:
              _showElements = [
                { label: Constants.dog_class_element.performance_weight_pull, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element.performance_weight_pull, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element.performance_weight_pull, checked: false, judge_name: "", judge_number: "" }
              ];
            break;
          }
        } else if (props.event.sanctioning_club === Constants.sanctioning_club.ABI) {
          switch(showType.id) {
            case Constants.show_type.conformation:
              _showElements = [
                { label: Constants.dog_class_element.conformation, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element.conformation_altered, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element.veteran, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element.brace, checked: false, judge_name: "", judge_number: "" }
                // { label: Constants.dog_class_element.abi_best_of_breed, checked: false, judge_name: "", judge_number: "" },
                // { label: Constants.dog_class_element.abi_bred_by_exibitor, checked: false, judge_name: "", judge_number: "" }
              ];
            break;
            case Constants.show_type.urban_rat_race:
              _showElements = [
                { label: Constants.dog_class_element.tubes, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element.unobstructed, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element.obstructed, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element.fantastic_five, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element.tempting_ten, checked: false, judge_name: "", judge_number: "" }
              ];
            break;
            case Constants.show_type.country_rat_race:
              _showElements = [
                { label: Constants.dog_class_element.performance_country_rat_race, checked: false, judge_name: "", judge_number: "" }
              ];
            break;
          }
        } else if (props.event.sanctioning_club === Constants.sanctioning_club.AKC) {
          switch(showType.id) {
            case Constants.show_type.fast_cat:
              _showElements = [
                { label: Constants.dog_class_element.fast_cat, checked: false, judge_name: "", judge_number: "" }
              ];
            break;
            case Constants.show_type.conformation:
              _showElements = [
                { label: Constants.dog_class_element_level.akc_6_9_month_puppy, checked: false, judge_name: "", judge_number: ""},
                { label: Constants.dog_class_element_level.akc_9_12_month_puppy, checked: false, judge_name: "", judge_number: ""},
                { label: Constants.dog_class_element_level.akc_12_18_month_puppy, checked: false, judge_name: "", judge_number: ""},
                { label: Constants.dog_class_element_level.akc_under_12_month_bred_by_exhibitor, checked: false, judge_name: "", judge_number: ""},
                { label: Constants.dog_class_element_level.akc_adult_bred_by_exhibitor, checked: false, judge_name: "", judge_number: ""},
                { label: Constants.dog_class_element_level.akc_amateur_owner_handler, checked: false, judge_name: "", judge_number: ""},
                { label: Constants.dog_class_element_level.akc_american_bred, checked: false, judge_name: "", judge_number: ""},
                { label: Constants.dog_class_element_level.akc_open, checked: false, judge_name: "", judge_number: ""},
                { label: Constants.dog_class_element_level.akc_best_of_breed, checked: false, judge_name: "", judge_number: ""},
                { label: Constants.dog_class_element_level.akc_veteran_8_10_years, checked: false, judge_name: "", judge_number: ""},
                { label: Constants.dog_class_element_level.akc_veteran_10_12_years, checked: false, judge_name: "", judge_number: ""},
                { label: Constants.dog_class_element_level.akc_veteran_13_plus_years, checked: false, judge_name: "", judge_number: ""},
                { label: Constants.dog_class_element.akc_sweepstakes, checked: false, judge_name: "", judge_number: ""},
                { label: Constants.dog_class_element.akc_futurity, checked: false, judge_name: "", judge_number: ""}
              ];
            break;
            case Constants.show_type.obedience:
            _showElements = [
                { label: Constants.dog_class_element_level.beginner_novice_a, checked: false, judge_name: "", judge_number: ""},
                { label: Constants.dog_class_element_level.beginner_novice_b, checked: false, judge_name: "", judge_number: ""},
                { label: Constants.dog_class_element_level.preferred_novice, checked: false, judge_name: "", judge_number: ""},
                { label: Constants.dog_class_element_level.graduate_novice, checked: false, judge_name: "", judge_number: ""},
                { label: Constants.dog_class_element_level.novice_a, checked: false, judge_name: "", judge_number: ""},
                { label: Constants.dog_class_element_level.novice_b, checked: false, judge_name: "", judge_number: ""},
                { label: Constants.dog_class_element_level.open_a, checked: false, judge_name: "", judge_number: ""},
                { label: Constants.dog_class_element_level.open_b, checked: false, judge_name: "", judge_number: ""},
                { label: Constants.dog_class_element_level.preferred_open, checked: false, judge_name: "", judge_number: ""},
                { label: Constants.dog_class_element_level.graduate_open, checked: false, judge_name: "", judge_number: ""},
                { label: Constants.dog_class_element_level.utility_a, checked: false, judge_name: "", judge_number: ""},
                { label: Constants.dog_class_element_level.utility_b, checked: false, judge_name: "", judge_number: ""},
                { label: Constants.dog_class_element_level.preferred_utility, checked: false, judge_name: "", judge_number: ""},
                { label: Constants.dog_class_element_level.versatility, checked: false, judge_name: "", judge_number: ""},
                { label: Constants.dog_class_element_level.veteran, checked: false, judge_name: "", judge_number: ""},
                { label: Constants.dog_class_element_level.abi_brace, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element_level.team, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element_level.wc_novice, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element_level.wc_open, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element_level.wc_utility, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element_level.udx, checked: false, judge_name: "", judge_number: "" }
              ];
            break;
            case Constants.show_type.rally_obedience:
              _showElements = [
                { label: Constants.dog_class_element_level.novice_a, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element_level.novice_b, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element_level.intermediate, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element_level.advanced_a, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element_level.advanced_b, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element_level.excellent_a, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element_level.excellent_b, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element_level.master, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element_level.choice, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element_level.pairs, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element_level.team, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element_level.rae, checked: false, judge_name: "", judge_number: "" },
                { label: Constants.dog_class_element_level.raem, checked: false, judge_name: "", judge_number: "" }
              ];
            break;
            case Constants.show_type.fetch:
              _showElements = [
                { label: Constants.dog_class_element.fetch, checked: false, judge_name: "", judge_number: "" }
              ];
            break;
          }
        }
      }

      if (props.show) {
        let sameJudgeName:string = "";

        for (let pse in props.show.show_elements) {
          if (sameJudgeName.length === 0) {
            sameJudgeName = props.show.show_elements[pse].judge_name;
          } else {
            if (sameJudgeName !== props.show.show_elements[pse].judge_name) {
              setUseSameJudge(false);
            }
          }

          for (let se in _showElements) {
            if ((props.show.show_elements[pse].level !== undefined && _showElements[se].label === props.show.show_elements[pse].level) ||
                (props.show.show_elements[pse].level === undefined && _showElements[se].label === props.show.show_elements[pse].show_element)) {
              _showElements[se].checked = true;
              _showElements[se].judge_name = props.show.show_elements[pse].judge_name;
              _showElements[se].judge_number = props.show.show_elements[pse].judge_number;

              if (props.show.show_elements[pse].fee !== undefined) {
                _showElements[se].price = props?.show?.show_elements[pse]?.fee?.toString();
              }

              if (props.show.show_elements[pse].early_bird_fee !== undefined) {
                _showElements[se].earlyBirdPrice = props?.show?.show_elements[pse]?.early_bird_fee?.toString();
              }

              break;
            }
          }
        }
      } else {
        for (let se in _showElements) {
          _showElements[se].checked = true;
        }
      }

      setShowElements(_showElements);
    }
  }, [pShowType, showType, props.show, props.event]);

  useEffect(() => {
    if (pUseCloseDate !== useCloseDate && useCloseDate) {
      if (!props?.show?.registration_end_date) {
        setCloseDate(showDate);
      }
    }
  }, [showDate, setCloseDate, useCloseDate, pUseCloseDate, props.show]);

  useEffect(() => {
    if (isTrailingShow && eventShows.length === 0 && props.event && props.clubId) {
      apiService.getClubEventShows(props.clubId, props.event._id, {
        sort: [{
          attribute_name: "show_type",
          sort: "asc"
        }, {
          attribute_name: "show_date",
          sort: "asc"
        }]
      }).then((showResponse: any[]) => {
        setEventShows(showResponse);

        let _showOptions:IOption[] = [{ value: "Select a Previous Show", id: "Select a Previous Show" }];

        for (let i in showResponse) {
          if ((props.show && props.show._id !== showResponse[i]._id) || (props.show === undefined)) {
            _showOptions.push({
              id: showResponse[i]._id,
              value: showResponse[i].show_type + " " + showResponse[i].show_name + " (" + moment(showResponse[i].show_date).format("MMM Do") + ")",
            });
          }
        }

        setEventShowOptions(_showOptions);
      });
    }
    
  }, [isTrailingShow, eventShows, setEventShows, setEventShowOptions, props.clubId, props.event, props.show]);

  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!loaded) {
      const hydrateShowFromProps = () => {
        if (props.show) {
          setShowName({ id: props?.show?.show_name, value: props?.show?.show_name });
          setShowDate(moment(props.show.show_date).toDate());
          setUseCloseDate(props.show.use_registration_end_date);
          setCloseDate(props.show.registration_end_date ? moment(props.show.registration_end_date).toDate() : new Date());
          setShowType({ value: props.show.show_type, id: props.show.show_type });
          setPrice(props.show.fee.toString());
          setUseEarlyBird(props.show.use_early_bird_fee);
          setEarlyBirdPrice(props.show.early_bird_fee ? props.show.early_bird_fee.toString() : "");
          setEarlyBirdEndDate(props.show.early_bird_end_date ? moment(props.show.early_bird_end_date).toDate() : new Date());
          setUseSamePrice(props.show.use_same_fee);
          //setRunDuration(props.show.run_duration_minutes?.toString() || "");
          setMaxDog(props.show.max_dogs?.toString() || "");
          setIsTrailingShow(props.show.trailing_show ? props.show.trailing_show : false);
          setPreviousShow(props.show.trailing_show ? { id: props.show.previous_show_name || "", value: props.show.previous_show_name || "" } : { value: "Select a Previous Show", id: "Select a Previous Show" });
          setAkcNum(props.show.event_number || "");
          setNotifEmails(props.show.notification_emails || []);

          if (props?.show?.show_type === Constants.show_type.fast_cat) {
            apiService.getShowRunTimeSlots(props?.show?.event_id, props?.show?._id).then((response) => {
              let _time_slots:ShowRunTimeSlotDtoId[] = [];

              for (let i in response) {
                _time_slots.push({
                  id: response[i]._id,
                  time_slot: response[i].time_slot,
                  num_available: response[i].num_available,
                  note: response[i].note ? response[i].note : "",
                });
              }

              setTimeslots(_time_slots);
            }).finally(() => {
              setLoaded(true);
            });
          } else {
            setLoaded(true);
          }
        } else {
          setLoaded(true);
        }
      }

      hydrateShowFromProps();

      if (props.event) {
        switch (props.event.sanctioning_club) {
            case Constants.sanctioning_club.UKC:
              setShowTypes([
                { value: Constants.show_type.nosework, id: Constants.show_type.nosework },
                { value: Constants.show_type.conformation, id: Constants.show_type.conformation },
                { value: Constants.show_type.obedience, id: Constants.show_type.obedience },
                { value: Constants.show_type.rally_obedience, id: Constants.show_type.rally_obedience },
                { value: Constants.show_type.agility, id: Constants.show_type.agility },
                { value: Constants.show_type.weight_pull, id: Constants.show_type.weight_pull }
              ]);
            break;
            case Constants.sanctioning_club.AKC:
              setShowTypes([
                { value: Constants.show_type.conformation, id: Constants.show_type.conformation },
                { value: Constants.show_type.obedience, id: Constants.show_type.obedience },
                { value: Constants.show_type.rally_obedience, id: Constants.show_type.rally_obedience },
                { value: Constants.show_type.fast_cat, id: Constants.show_type.fast_cat },
                { value: Constants.show_type.fetch, id: Constants.show_type.fetch }
              ]);
            break;
            case Constants.sanctioning_club.ABI:
              setShowTypes([
                // { value: Constants.show_type.nosework, id: Constants.show_type.nosework },
                { value: Constants.show_type.conformation, id: Constants.show_type.conformation },
                // { value: Constants.show_type.obedience, id: Constants.show_type.obedience },
                // { value: Constants.show_type.rally_obedience, id: Constants.show_type.rally_obedience },
                // { value: Constants.show_type.agility, id: Constants.show_type.agility },
                // { value: Constants.show_type.weight_pull, id: Constants.show_type.weight_pull },
                { value: Constants.show_type.urban_rat_race, id: Constants.show_type.urban_rat_race },
                { value: Constants.show_type.country_rat_race, id: Constants.show_type.country_rat_race }
              ]);
            break;
        }
      }
    }
  }, [loaded, props.show, showElements, props.event]);

  if (loaded) {
    return (
      <div className="ShowForm">
        <div className="title">{props.show ? "Edit Show": "Create a Show"}</div>
        <div className="formWrap">
          <StepForm activeTab={activeTab} next={switchToTab} steps={showType.id === Constants.show_type.fast_cat ? ["Show Type", "Show Name and Date", "Run Times", "Judge", "Notifications", "Price"] : ["Show Type", "Show Name and Date", "Judge", "Notifications", "Price"]} onSubmit={createShow} isLoading={isCreatingShow}>
            <Step>
              <div className="stepTitle">Show Type and Date</div>
              <p>What type of show are you running?</p>
              <Dropdown value={showType} onChange={(e: any, value: IOption) => { setShowType(value); }} label="Show Type" options={showTypes} placeholder="Choose a Show Type" />
              <>{showElements.length > 0 && !showTypeHasElements(showType.id) && <div>
                <p>What elements are available at your show?</p>
                {showElements.map((check:ICheckJudge, index:number) => {
                  return(<div key={"showType" + index} className="showTypeWrap">
                    <Checkbox key={"elem" + index} onChange={(e) => { toggleCheck(check.label); }} value={check.checked} id={check.label} label={check.label} />
                  </div>);
                })}
              </div>}</>
            </Step>
            <Step>
              <div className="stepTitle">Show Type and Date</div>
              <p>Whats the name of your show, and when is it?</p>
              <>{props.event?.sanctioning_club === Constants.sanctioning_club.AKC && <Input required label="AKC Number" defaultValue={akcNum} id="akcNum" type="text" onChange={(e) => { e.stopPropagation(); setAkcNum(e.target.value); }} />}</>
              <Dropdown value={showName} onChange={(e: any, value: IOption) => {
                setShowName(value);

                if (!isSecondShow()) {
                  setIsTrailingShow(false);
                }
              }} label="Show Name" options={getShowNamesByType(showType.id)} placeholder="Select a Show Name" />
              <>{isSecondShow() && <div className="trailingShow">
                <Checkbox onChange={(e:any) => { setIsTrailingShow(!isTrailingShow); }} value={isTrailingShow} id="isTrailingShow" label="This Show Follows a Previous Show" />
                {isTrailingShow && <div className="selectTrailingShow">
                  <Dropdown value={previousShow} onChange={(e: any, value: IOption) => { setPreviousShow(value); }} label="Previous Show" options={eventShowOptions} placeholder="Choose a Previous Show" />
                </div>}
              </div>}</>
              <>{!isTrailingShow && <DatePick className="startDateTime" label="Start Date & Time" onChange={setShowDate} value={showDate} showTimeSelect={true} />}</>
              <Checkbox onChange={(e:any) => { setUseCloseDate(!useCloseDate); }} value={useCloseDate} id="useCloseDate" label="Use an Entry Close Date" />
              <>{useCloseDate &&
                <div className="earlyBird">
                  <p>What day should we close entries on?</p>
                  <DatePick label="Entry Close Date" onChange={setCloseDate} value={closeDate} showTimeSelect={true} />
                </div>
              }</>
            </Step>
            <>
            {(showType.id === Constants.show_type.fast_cat) &&
              <Step>
                <div className="stepTitle">Run Times</div>
                <p>Create timeslots for your attendees to select when registering. Include a label, total amount of dogs that can attend during that timeslot and an optional note for the attendees about the timeslot.</p>
                {loaded && <Timeslots timeslots={timeslots} onChange={(timeslots: ShowRunTimeSlotDtoId[]) => {
                  setTimeslots(timeslots);
                }} />}
                {/*<p>How long is a run, when do they start, and how many dogs are at your show?</p>
                <DatePick className="firstRunTime" label="First Run Time" onChange={setFirstRunTime} minDate={new Date(showDate)} maxDate={new Date(showDate)} value={firstRunTime} showTimeSelect={true} />
                <Input required label={"Run Duration (Minutes)"} id="runDuration" type="number" step="1" onChange={(e) => { e.stopPropagation(); setRunDuration(e.target.value); }} defaultValue={runDuration} />
                <Input required min={0} label={"Dog Limit"} id="maxDog" type="number" onChange={(e:any) => { e.stopPropagation(); setMaxDog(e.target.value); }} placeholder="What's the maximum amount of dog registrants?" defaultValue={maxDog} />*/}
              </Step>
            }
            </>
            <Step>
              <div className="stepTitle">Judge</div>
                <div><p>Who are the judges for your show?</p>
                  <div className="judgeCheckWrap">
                    <Checkbox id="sameJudge" label="Use the same judge for all shows" value={useSameJudge} onChange={(e:any) => { setUseSameJudge(!useSameJudge); }} />
                  </div>
                  <>{!useSameJudge && 
                    <div>
                      {showElements.map((check:ICheckJudge, index:number) => {
                        if (check.checked) {
                          return(<div key={"judge" + index} className="judgeInfo">
                            <Input key={"name" + index} required label={check.label + " Judge Name"} id={check.label + "judgeName"} type="text" onChange={(e) => { updateJudge(index, { judge_name: e.target.value }) }} placeholder={"What's the "+ check.label.toLowerCase() +" judges name?"} defaultValue={check.judge_name} />
                            <Input key={"num" + index} label={"Judge #"} id={check.label + "judgeNum"} type="text" onChange={(e) => { updateJudge(index, { judge_number: e.target.value }) }} defaultValue={check.judge_number} />
                          </div>);
                        } else {
                          return(null);
                        }
                      })}
                    </div>
                  }</>
                  <>{useSameJudge && 
                      <div key={"judge"} className="judgeInfo">
                        <Input key={"name"} required label={" Judge Name"} id={"judgeName"} type="text" onChange={(e) => { updateSingleJudge({ judge_name: e.target.value }) }} placeholder={"What's the judges name?"} defaultValue={showElements.length > 0 ? showElements[0].judge_name : ""} />
                        <Input key={"num"} label={"Judge #"} id={"judgeNum"} type="text" onChange={(e) => { updateSingleJudge({ judge_number: e.target.value }) }} defaultValue={showElements.length > 0 ? showElements[0].judge_number : ""} />
                      </div>
                  }</>
                </div>
            </Step>
            <Step>
              <div className="stepTitle">Notifications</div>
              <p>Are there any emails you want to subscribe to notifications? These emails will be notified when a user registers to this show.</p>
              <div className="notifWrap">
                <div className={notifRender ? "notifInputWrap reload" : "notifInputWrap"}>
                  {!notifRender && <Input label="Notification Email" type="email" defaultValue={notifEmail} onChange={(e) => { e.stopPropagation(); setNotifEmail(e.target.value); }} onKeyDown={(e) => { if (e.code === "Enter") { e.preventDefault(); submitNotifEmail(notifEmail); } }} placeholder="Enter an Email" />}
                </div>
                <Button onClick={(e) => { e.preventDefault(); submitNotifEmail(notifEmail); }}>Add</Button>
              </div>
              <div className="notifList">
                {notifEmails.map((email: string, index: number) => {
                  return(<Chip onRemove={() => { removeNotifEmail(index); }}>{email}</Chip>);
                })}
              </div>
            </Step>
            <Step>
              <div className="stepTitle">Price</div>
              <p>What is the price of this show?</p>
              <>{showType.id === Constants.show_type.conformation && <div className="judgeCheckWrap">
                <Checkbox id="samePrice" label="Use the same price for all shows" value={useSamePrice} onChange={(e:any) => { if (useSamePrice && showType.id === Constants.show_type.conformation && price.length > 0) { setNonLicensedPrices(price); } setUseSamePrice(!useSamePrice); }} />
              </div>}</>
              <Input required label={"Price (" + (props.currency?.toUpperCase() || "USD") + ")"} id="price" type="text" onChange={(e) => { e.stopPropagation(); setPrice(e.target.value); }} placeholder="What's the price of your show?" defaultValue={price} />
              <>{showType.id === Constants.show_type.conformation && !useSamePrice && <div className="nonLicensedPrices">
                {showElements.filter((checkJudge: ICheckJudge) => nonLicensed.includes(checkJudge.label) && checkJudge.checked).map((checkJudge: ICheckJudge, index:number) => {
                  return(<div key={"price" + index} className="inputwrap">
                    <Input type="text" label={checkJudge.label + " Price (" + (props.currency?.toUpperCase() || "USD") + ")"} onChange={(e) => { updateNonLicensedPrice(checkJudge.label, e.target.value); }} placeholder={"What's the price of your "+ checkJudge.label +" show?"} defaultValue={checkJudge.price} />
                  </div>);
                })}
              </div>}</>
              <Checkbox onChange={(e:any) => { setUseEarlyBird(!useEarlyBird); }} value={useEarlyBird} id="earlyBird" label="Use Early Bird Prices" />
              <>{useEarlyBird &&
                <div className="earlyBird">
                  <p>What day do early bird prices end?</p>
                  <DatePick label="Early Bird End Date" onChange={setEarlyBirdEndDate} value={earlyBirdEndDate} showTimeSelect={true} />
                  <Input required label={"Early Bird Price (" + (props.currency?.toUpperCase() || "USD") + ")"} id="earlyBirdPrice" type="text" onChange={(e) => { e.stopPropagation(); if (useEarlyBird) { setNonLicensedPrices(price); } setEarlyBirdPrice(e.target.value); }} placeholder="What's the early bird price?" defaultValue={earlyBirdPrice} />
                  <>{showType.id === Constants.show_type.conformation && !useSamePrice && <div className="nonLicensedPrices">
                    {showElements.filter((checkJudge: ICheckJudge) => nonLicensed.includes(checkJudge.label) && checkJudge.checked).map((checkJudge: ICheckJudge, index:number) => {
                      return(<div key={"price" + index} className="inputwrap">
                        <Input type="text" label={checkJudge.label + " Early Bird Price (" + (props.currency?.toUpperCase() || "USD") + ")"} onChange={(e) => { updateNonLicensedPrice(checkJudge.label, e.target.value, true); }} placeholder={"What's the early bird price of your "+ checkJudge.label +" show?"} defaultValue={checkJudge.earlyBirdPrice} />
                      </div>);
                    })}
                  </div>}</>
                </div>
              }</>
              <>{(showType.id !== Constants.show_type.fast_cat) && <div className="maxDogWrap">
                <Input min={0} label={"Dog Limit (optional)"} id="maxDog" type="number" onChange={(e:any) => { e.stopPropagation(); setMaxDog(e.target.value); }} placeholder="What's the maximum amount of dog registrants?" defaultValue={maxDog} />
              </div>}</>
            </Step>
          </StepForm>
        </div>
      </div>
    );
  } else {
    return(null);
  }
};

export default ShowForm;
