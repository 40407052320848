import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { helperService } from "../../services/helper.service";
import { Helmet } from "react-helmet";

import "./Register.scss";

import logo from "../../assets/logo.svg";

interface IProps {}

interface IUserType {
  icon: string,
  title: string,
  description: string,
  features: string[],
  link: string
}

const Register: React.FC<IProps> = (props) => {
  const navigate = useNavigate();

  let _url = window.location.href.split("/e/")[1];
  let redirect = _url ? `/e/${_url}` : "/events";
  const loginLink = '/login';

  const userTypes:IUserType[] = [
    {
      icon: "trophy",
      title: "Kennel Club",
      description: "If you're looking to run your club's next event, create a Kennel Club account to get started today!",
      features: [
        "Accept online registrations and automatically generate judges books.", 
        "Better user experience allows multiple online payment options and no paperwork.", 
        "Reduce paperwork and save time for your team so they can focus on running great events!"
      ],
      "link": "/register/kennel-club"
    }, {
      icon: "paw",
      title: "Dog Owner",
      description: "Create your dog owner profile to make registering to new events quick and painless!",
      features: [
        "No more paper registrations!  Save your dogs and make registering for events simple and fast.", 
        "Multiple online payment options available to make payments fast, simple and secure.  No more sending cash in the mail!", 
        "A single place to manage your event schedule and all of your dog information."
      ],
      "link": !redirect ? "/register/dog-owner" : `/register/dog-owner?r=${redirect}`
    }
  ];

  useEffect(() => {
    if (helperService.isLoggedIn()) {
      navigate("/events");
    }
  });

  return (
    <div className="Register">
      <Helmet>
        <title>Register</title>
        <meta name="description" content="Register a kennel club or dog owner account on Eager Dog." />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div className="logo"><Link to="/"><img src={logo} alt="Eager Dog" /></Link></div>
      <div className="content">
        <div className="inner">
          <div className="title">
            <h1>Register</h1>
            <p>Choose a role that suits you</p>
          </div>
          <div className="login">
             Already have an account? <Link to={loginLink}>Login</Link>                               
          </div>
          <ul className="options">
            {userTypes.map((ut:IUserType, index:number) => {
              return(
                <li key={index} className="userType">
                  <div className="iconWrap">
                    <div className={"icon " + ut.icon}></div>
                  </div>
                  <div className="innerUserType">
                      <div className="contentInner">
                      <h2>{ut.title}</h2>
                      <p>{ut.description}</p>
                      <ul className="features">
                        {ut.features.map((f:string, index2:number) => {
                          return(
                            <li key={index2}><div className="featureIcon"></div><span>{f}</span></li>
                          );
                        })}
                      </ul>
                      <div className="actions">
                        <Link to={ut.link}>Get Started</Link>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Register;
